@import url(https://use.typekit.net/uhc7kya.css);
*,
*:before,
*:after {
    box-sizing: border-box;
}

.App {
    display: flex;
    min-width: 100vw;
    min-height: 100vh;
    line-height: 1.4;
    font-family: 'proxima-nova', sans-serif;
    color: #2d364c;
    position: relative;
}

.App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
    pointer-events: none;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@keyframes App-logo-spin {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

.item-enter {
    opacity: 0;
}
.item-enter-active {
    opacity: 1;
    -webkit-transition: opacity 1000ms ease-in;
    transition: opacity 1000ms ease-in;
}
.item-exit {
    opacity: 1;
}
.item-exit-active {
    opacity: 0;
    -webkit-transition: opacity 1000ms ease-in;
    transition: opacity 1000ms ease-in;
}

.aws-sld__box,
.aws-sld__container figure,
.aws-sld__content {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}
.aws-sld__bullets button,
.aws-sld__controls button {
    outline-color: 0;
    outline-style: none;
    outline-width: 0;
}
.aws-sld {
    --organic-arrow-thickness: 5px;
    --organic-arrow-height: 35px;
    --slider-height-percentage: 60%;
    --loader-bar-color: #851515;
    --loader-bar-height: 6px;
    --control-button-width: 20%;
    --control-button-height: 25%;
    --control-button-opacity: 1;
    --control-button-hover-opacity: 0.75;
    --control-button-background: transparent;
    --transition-bezier: cubic-bezier(0.5, 0.075, 0.25, 0.95);
    --slider-transition-duration: 575ms;
    --organic-arrow-color: #6a6a6a;
    --organic-arrow-border-radius: 0;
    --control-bullet-color: #6a6a6a;
    --control-bullet-active-color: #6a6a6a;
    --content-background-color: #2f2f2f;
    max-width: 100%;
}
.aws-sld,
.aws-sld__wrapper {
    display: block;
    position: relative;
    width: 100%;
}
.aws-sld__wrapper {
    overflow: hidden;
    height: 100%;
}
.aws-sld__container {
    display: block;
    width: 100%;
    height: 0;
    padding-bottom: var(--slider-height-percentage);
}
@media (max-width: 500px) {
    .aws-sld__container {
        padding-bottom: calc(var(--slider-height-percentage) * 1.25);
    }
}
.aws-sld__startUp {
    background-color: red;
    height: 100%;
    width: 100%;
}
.aws-sld__startUp > div {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.aws-sld__startUp img {
    width: 35%;
    height: auto;
}
.aws-sld__content {
    background-color: var(--content-background-color);
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}
.aws-sld__content > img,
.aws-sld__content > video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}
.aws-sld__controls button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: var(--control-button-width);
    height: var(--control-button-height);
    position: absolute;
    z-index: 10;
    top: calc(50% - (0.5 * var(--control-button-height)));
    border: none;
    background-color: var(--control-button-background);
    color: #fff;
    cursor: pointer;
}
.aws-sld__controls button .aws-sld__controls__arrow-left,
.aws-sld__controls button .aws-sld__controls__arrow-right {
    opacity: var(--control-button-opacity);
}
.aws-sld__controls button:hover .aws-sld__controls__arrow-left,
.aws-sld__controls button:hover .aws-sld__controls__arrow-right {
    opacity: var(--control-button-opacity-hover);
}
.aws-sld__controls--active .aws-sld__controls__arrow-left {
    opacity: var(--control-button-opacity-hover);
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
}
.aws-sld__controls--active .aws-sld__controls__arrow-right {
    opacity: var(--control-button-opacity-hover);
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
}
@media (max-width: 520px) {
    .aws-sld__controls {
        visibility: hidden;
    }
}
.aws-sld__bar {
    display: block;
    width: 100%;
    height: var(--loader-bar-height);
    background-color: var(--loader-bar-color);
    position: absolute;
    top: 0;
    left: 0;
    -webkit-transition: -webkit-transform 3s var(--transition-bezier);
    transition: -webkit-transform 3s var(--transition-bezier);
    transition: transform 3s var(--transition-bezier);
    transition: transform 3s var(--transition-bezier), -webkit-transform 3s var(--transition-bezier);
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
}
.aws-sld__bar--active {
    -webkit-transform: translate3d(-20%, 0, 0);
    transform: translate3d(-20%, 0, 0);
}
.aws-sld__bar--end {
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
}
.aws-sld__next {
    right: 0;
}
.aws-sld__prev {
    left: 0;
}
.aws-sld__box {
    z-index: 1;
}
.aws-sld--animated {
    -webkit-transition: -webkit-transform var(--slider-transition-duration) var(--transition-bezier);
    transition: -webkit-transform var(--slider-transition-duration) var(--transition-bezier);
    transition: transform var(--slider-transition-duration) var(--transition-bezier);
    transition: transform var(--slider-transition-duration) var(--transition-bezier), -webkit-transform var(--slider-transition-duration) var(--transition-bezier);
    transition: transform var(--slider-transition-duration) var(--transition-bezier),
        -webkit-transform var(--slider-transition-duration) var(--transition-bezier);
}
.aws-sld--animated-mobile {
    -webkit-transition: -webkit-transform 325ms cubic-bezier(0.15, 0.65, 0.1, 1);
    transition: -webkit-transform 325ms cubic-bezier(0.15, 0.65, 0.1, 1);
    transition: transform 325ms cubic-bezier(0.15, 0.65, 0.1, 1);
    transition: transform 325ms cubic-bezier(0.15, 0.65, 0.1, 1), -webkit-transform 325ms cubic-bezier(0.15, 0.65, 0.1, 1);
    transition: transform 325ms cubic-bezier(0.15, 0.65, 0.1, 1),
        -webkit-transform 325ms cubic-bezier(0.15, 0.65, 0.1, 1);
}
.aws-sld--active {
    z-index: 2;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
}
.aws-sld--moveRight {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
}
.aws-sld--moveLeft {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
}
.aws-sld--exit {
    z-index: 0;
}
.aws-sld--first .aws-sld__prev,
.aws-sld--last .aws-sld__next {
    visibility: hidden;
}
.aws-sld--fill-parent {
    position: absolute !important;
    width: 100% !important;
    height: 100% !important;
    top: 0;
    left: 0;
}
.aws-sld--fill-parent .aws-sld__container {
    height: 100%;
    padding: 0;
}
.aws-sld__bullets {
    position: absolute;
    bottom: -40px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.aws-sld__bullets button {
    padding: 0;
    display: block;
    width: 16px;
    height: 16px;
    margin: 5px;
    border-radius: 50%;
    background: var(--control-bullet-color);
    text-indent: -9999px;
    overflow: hidden;
    cursor: pointer;
    border: none;
    -webkit-transition: background-color 0.175s ease-out, -webkit-transform 0.225s cubic-bezier(0.8, 1.35, 0.75, 1.45);
    transition: background-color 0.175s ease-out, -webkit-transform 0.225s cubic-bezier(0.8, 1.35, 0.75, 1.45);
    transition: transform 0.225s cubic-bezier(0.8, 1.35, 0.75, 1.45), background-color 0.175s ease-out;
    transition: transform 0.225s cubic-bezier(0.8, 1.35, 0.75, 1.45), background-color 0.175s ease-out, -webkit-transform 0.225s cubic-bezier(0.8, 1.35, 0.75, 1.45);
    transition: transform 0.225s cubic-bezier(0.8, 1.35, 0.75, 1.45), background-color 0.175s ease-out,
        -webkit-transform 0.225s cubic-bezier(0.8, 1.35, 0.75, 1.45);
}
.aws-sld__bullets .aws-sld__bullets--loading,
.aws-sld__bullets button:hover {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
}
.aws-sld__bullets .aws-sld__bullets--active {
    background: var(--control-bullet-active-color);
}
.aws-sld__bullets .aws-sld__bullets--active,
.aws-sld__bullets .aws-sld__bullets--active:hover {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
}
.aws-sld__controls__arrow-left,
.aws-sld__controls__arrow-right {
    width: 100%;
    height: var(--organic-arrow-height);
    position: relative;
    display: block;
    -webkit-transition: opacity 0.2s ease-out, -webkit-transform 0.2s ease-out 0.125s;
    transition: opacity 0.2s ease-out, -webkit-transform 0.2s ease-out 0.125s;
    transition: transform 0.2s ease-out 0.125s, opacity 0.2s ease-out;
    transition: transform 0.2s ease-out 0.125s, opacity 0.2s ease-out, -webkit-transform 0.2s ease-out 0.125s;
}
.aws-sld__controls__arrow-left:after,
.aws-sld__controls__arrow-left:before,
.aws-sld__controls__arrow-right:after,
.aws-sld__controls__arrow-right:before {
    content: ' ';
    position: absolute;
    right: calc(50% - (0.7071 * (var(--organic-arrow-height) + var(--organic-arrow-thickness))) / 2);
    height: 100%;
    border-radius: var(--organic-arrow-border-radius);
    width: var(--organic-arrow-thickness);
    background-color: var(--organic-arrow-color);
    -webkit-transition: background-color 0.15s ease-out, -webkit-transform 0.15s ease-out;
    transition: background-color 0.15s ease-out, -webkit-transform 0.15s ease-out;
    transition: transform 0.15s ease-out, background-color 0.15s ease-out;
    transition: transform 0.15s ease-out, background-color 0.15s ease-out, -webkit-transform 0.15s ease-out;
}
.aws-sld__controls__arrow-left:before,
.aws-sld__controls__arrow-right:before {
    -webkit-transform-origin: 100% 100% 0;
    transform-origin: 100% 100% 0;
    top: -50%;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}
.aws-sld__controls__arrow-left:after,
.aws-sld__controls__arrow-right:after {
    -webkit-transform-origin: 100% 0 0;
    transform-origin: 100% 0 0;
    top: 50%;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}
.aws-sld__controls__arrow-right--active {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
}
.aws-sld__controls__arrow-right--active:after {
    -webkit-transform: rotate(90deg) translate3d(50%, 0, 0) !important;
    transform: rotate(90deg) translate3d(50%, 0, 0) !important;
}
.aws-sld__controls__arrow-right--active:before {
    -webkit-transform: rotate(-90deg) translate3d(50%, 0, 0) !important;
    transform: rotate(-90deg) translate3d(50%, 0, 0) !important;
}
.aws-sld__controls__arrow-left:after,
.aws-sld__controls__arrow-left:before {
    right: auto;
    left: calc(50% - (0.7071 * (var(--organic-arrow-height) + var(--organic-arrow-thickness))) / 2);
}
.aws-sld__controls__arrow-left:before {
    -webkit-transform-origin: 0 100% 0;
    transform-origin: 0 100% 0;
    top: -50%;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}
.aws-sld__controls__arrow-left:after {
    -webkit-transform-origin: 0 0 0;
    transform-origin: 0 0 0;
    top: 50%;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}
.aws-sld__controls__arrow-left--active {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
}
.aws-sld__controls__arrow-left--active:after {
    -webkit-transform: rotate(-90deg) translate3d(-50%, 0, 0) !important;
    transform: rotate(-90deg) translate3d(-50%, 0, 0) !important;
}
.aws-sld__controls__arrow-left--active:before {
    -webkit-transform: rotate(90deg) translate3d(-50%, 0, 0) !important;
    transform: rotate(90deg) translate3d(-50%, 0, 0) !important;
}
.aws-sld__controls button:hover .aws-sld__controls__arrow-left:before {
    opacity: 1;
    -webkit-transform: rotate(30deg);
    transform: rotate(30deg);
}
.aws-sld__controls button:hover .aws-sld__controls__arrow-left:after,
.aws-sld__controls button:hover .aws-sld__controls__arrow-right:before {
    opacity: 1;
    -webkit-transform: rotate(-30deg);
    transform: rotate(-30deg);
}
.aws-sld__controls button:hover .aws-sld__controls__arrow-right:after {
    opacity: 1;
    -webkit-transform: rotate(30deg);
    transform: rotate(30deg);
}
.aws-sld {
    --slider-height-percentage: 60%;
    --slider-transition-duration: 700ms;
    --organic-arrow-thickness: 3px;
    --organic-arrow-border-radius: 30px;
    --organic-arrow-height: 50px;
    --organic-arrow-color: #ffffff;
    --control-button-width: 5%;
    --control-button-height: 25%;
    --loader-bar-color: #2d364c;
    --loader-bar-height: 4px;
}
.aws-sld__controls__arrow-left {
    display: none !important;
}

/* .rotate-overlay-appear {
    opacity: 0;
  } */
.rotate-overlay-enter {
    opacity: 0;
    -webkit-transition: opacity 200ms;
    transition: opacity 200ms;
}
.rotate-overlay-enter-done {
    opacity: 1;
    -webkit-transition: opacity 200ms;
    transition: opacity 200ms;
}
.rotate-overlay-exit {
    opacity: 1;
    -webkit-transition: opacity 200ms;
    transition: opacity 200ms;
}
.rotate-overlay-exit-done {
    opacity: 0;
    -webkit-transition: opacity 200ms;
    transition: opacity 200ms;
}
